<template>
  <modal
    :name="REJECT_REASON_MODAL"
    :adaptive="true"
    height="auto"
    :width="480"
    @closed="close()"
    style="z-index: 2000"
  >
    <ValidationObserver ref="modalIdCardRejectReasonObserver" slim>
      <div class="modal-container w-100">
        <div class="d-flex align-items-center justify-content-between p-1">
          <p class="f-16 text-black font-600 mb-0">Reject Reason</p>
          <div
            class="
              d-flex
              align-items-center
              justify-content-center
              rounded-circle
              cursor-pointer
            "
            style="
              width: 24px;
              height: 24px;
              background-color: rgb(228, 232, 237);
            "
            @click="close()"
          >
            <i class="fa fa-close" style="color: rgb(17, 24, 32)"></i>
          </div>
        </div>
        <hr class="m-0" />
        <div class="p-1">
          <ValidationProvider
            name="reject reason"
            v-slot="{ errors }"
            :rules="{
              max: 120,
              required: true,
            }"
            slim
          >
            <textarea
                maxlength="120"
                rows="3"
                class="form-control"
                :class="{ 'validation-warning': errors[0] }"
                placeholder="e.g: ID card is blurry or not clearly visible"
                v-model="rejectReason"
                style="resize: none;"
            >
            </textarea>
            <div class="f-12 d-flex align-items-center justify-content-between" style="gap: 1rem; margin-top: 4px;">
              <div class="text-danger" v-if="errors[0]">{{ errors[0] }}</div>
              <div
                style="flex: 1 0 auto;"
                class="text-right"
                :class="{ 'text-danger': errors[0] }"
              >
                  {{ rejectReason === null ? 0 : rejectReason.length }}/120
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="p-1 d-flex align-items-center" style="background-color: #f2f4f6; gap: 16px;">
          <button
            class="btn btn-cancel rounded w-100 m-0"
            @click="close()"
          >
            Cancel
          </button>
          <button
            class="btn btn-reject rounded w-100 m-0"
            @click="handleRejectReason()"
            :disabled="
              rejectReason === null ||
              (rejectReason && rejectReason.trimStart().length < 1)
            "
          >
            Reject
          </button>
        </div>
      </div>
    </ValidationObserver>
  </modal>
</template>
<script>
const REJECT_REASON_MODAL = 'reject-reason-modal';

export default {
  props: {
    isOpen: Boolean,
    bookingNumber: String,
  },
  data() {
    return {
      rejectReason: null,
      REJECT_REASON_MODAL,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async handleRejectReason() {
      const isValid = await this.$refs.modalIdCardRejectReasonObserver.validate();
      if (isValid) {
        this.axios
        .post(`/v2/clinical/offline-clinic-booking-detail/${this.bookingNumber}/reject-id-card`, {
          reason: this.rejectReason,
        })
        .then((res) => {
          this.$swal.fire({
            title: res.data.message,
            text: 'Id card verification rejected',
            icon: 'success',
            confirmButtonColor: '#0036A0',
            confirmButtonText: 'OK'
          });
          this.close();
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error!',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.$emit('save');
        });
      }
    },
  },
  watch: {
    isOpen: function (isOpen) {
      if (isOpen) {
        this.rejectReason = null;
        this.$modal.show(REJECT_REASON_MODAL);
      } else {
        this.$modal.hide(REJECT_REASON_MODAL);
      }
    },
  },
}
</script>

<style scoped>
.modal-container {
  overflow: visible;
  height: 100%;
}

.btn-cancel {
  border-radius: 8px;
  border: 1px solid #bcc0d1;
  color: #5b5e62;
  background-color: #ffffff;
}

.btn-reject {
  color: #ffffff;
  background-color: #EB5757;
}

.btn-reject:disabled {
  background-color: rgba(17, 24, 32, 0.4);
}
</style>